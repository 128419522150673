/*
 * File: Collection.tsx
 * Project: autobuy-back-office
 * File Created: Friday, 4th August 2023 01:03:31
 * Author: Simon (s.gourlet@autobuy.fr)
 * -----
 * Last Modified: Monday, 21st August 2023 02:37:13
 * Modified By: Simon (s.gourlet@autobuy.fr>)
 * -----
 * Copyright 2022 - 2023 SAS Olivier Dubois, SAS Olivier Dubois
 */


import {Fragment, MouseEventHandler, useEffect, useRef, useState } from "react";
import {Button} from "react-bootstrap";
import DragGalery from "Content/DragGalery/DragGalery";
import translator from "MultiLanguage/TranslationWrapper";
import Styles from "../Visuals.module.css";
import { CollectionProps, VisualsType } from "Type/visuals.type";
import CollectionService from "Services/Visuals/Collection/CollectionService";
import ModalVisual from "Content/Modals/ModalVisual/ModalVisual";
import VisualService from "Services/Visuals/VisualService";
import { ModalConfirmRequest } from "Content/Modals/ModalConfirmRequest/ModalConfirmRequest";
import ModalConfirmation from "Content/Modals/ModalConfirmation/ModalConfirmation";

function setVisualList(val: VisualsType, index: number) {
    if (val.Picture)
        return {id: val.id, order: index, Name: val.Picture.Name, Url: val.Picture.URI };
    else if (val.Video)
        return {id: val.id, order: index, Name: val.Video.Name, Url: val.Video.URI };
    return {id: "", order: index, Name: "", Url: ""};
};

function CollectionVisuals(props: CollectionProps) {
    const visualList = useRef<VisualsType[]>([]);
    const generalVisualTitle = translator.decode(`visuals.titles.${props.name}`);
    const [boxes, setBoxes] = useState(visualList.current && visualList.current.length && visualList.current.length > 0  ? visualList.current.map(setVisualList): []);
    const [collectionID, setCollectionID] = useState("");
    const [showModalGroup, setShowModalGroup] = useState(false);
    const [showModalConfirm, setOpenModalRequest] = useState(false);
    const [requestFunc, setRequestFunc] = useState<Function>(() => () => {});
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [isOkay, setIsOkay] = useState<boolean>(false);

    useEffect(() => {
        CollectionService.getCollectionByName(props.name).then((val) => {
            setCollectionID(val.id);
            visualList.current = val.Visuals;
        }).catch((e) => {
            console.error(`Unable to load collection: ${props.name} ===> ${JSON.stringify(e)}`);
        });
    }, [props.name]);

    useEffect(() => {
        setBoxes(visualList.current && visualList.current.length && visualList.current.length > 0 ? visualList.current.map(setVisualList): []);
    }, [visualList.current]);

    const handleNewPicture = (visual:VisualsType) => {
        visualList.current = [...visualList.current, visual];
    };

    const handleDeleteVisual = (id: string) => {
        VisualService.deleteVisual(id).then(() => {
            const tmp = visualList.current.filter(x => x.id !== id);
            visualList.current = [...tmp];
            setIsOkay(true);
        }).catch((error) => {
            setIsOkay(false);
            console.log(error);
        }).finally(() => {
            setOpenModal(true);
            setOpenModalRequest(false);
        });
    };

    const handleToggleDeleteVisualModal = (e: React.MouseEvent<HTMLElement>) => {
        setOpenModalRequest(false);
    };
    const openModalRequest = (id: string) => {
        setRequestFunc(() => () => handleDeleteVisual(id));
        setOpenModalRequest(true);
    };

    const handleToggleVisualModal = (e: React.MouseEvent<HTMLElement>) => {
        setShowModalGroup(false);
    };
    const handleModalClose = () => { setOpenModal(false); };
    const showNewVisualModal:MouseEventHandler<HTMLButtonElement> = (e) => {e.preventDefault(); setShowModalGroup(true)};

    return (
        <Fragment>
            <h3 className="w-25 m-auto"> {generalVisualTitle} </h3>
            <div className={`${Styles.picHolder}`}>
                <Button onClick={showNewVisualModal} className="mb-3 ps-2 ms-2">{translator.decode("dynamic.create")}</Button>
                <DragGalery items={boxes} func={setBoxes} editable onDelete={openModalRequest} />
                <Button onClick={showNewVisualModal} className="m-3 ps-2 ms-2">{translator.decode("dynamic.create")}</Button>
            </div>
            <ModalVisual title={translator.decode("visuals.modal.title")} show={showModalGroup} onClose={handleToggleVisualModal} closeModal={handleToggleVisualModal} callback={handleNewPicture} collectionID={collectionID} collectionName={props.name} />
            <ModalConfirmRequest show={showModalConfirm} onClose={handleToggleDeleteVisualModal} closeModal={handleToggleDeleteVisualModal} request={requestFunc} title={translator.decode("dynamic.delete")} />
            <ModalConfirmation show={openModal} onClose={handleModalClose} closeModal={handleModalClose} isOkay={isOkay} />
        </Fragment>
    );
}

export default CollectionVisuals;
